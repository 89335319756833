


















import { Component, Prop, Vue } from "vue-property-decorator";

interface ErrorInterface {
  status: number;
  statusText: string;
}

@Component
export default class ErrorPage extends Vue implements ErrorInterface {
  @Prop(XMLHttpRequest) readonly error!: XMLHttpRequest;

  status: number;
  statusText: string;

  constructor() {
    super();

    try {
      this.status = this.error.status;
      this.statusText = this.error.statusText || "Network Error";
    } catch (e) {
      this.status = 400;
      this.statusText = "Bad Request";
    }
  }

  created() {
    this.showSnack();
  }

  showSnack() {
    this.$store.commit("snack/setSnack", {
      status: "error",
      text: this.statusText
    });
  }

  go(route) {
    this.$router.push({ name: route });
  }
}
